/*
  *demo
  bill_yang 2021.06
*/
// loading
import { TOGGLE_COUPON } from 'redux/constant';
import { setLocalStorage } from '../../untils/auth';

export function getLoading(preState = false, action) {
  let { type } = action;
  switch (type) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return preState;
  }
}

localStorage.getItem('token');
// token
let token = !localStorage.getItem('token') ? false : localStorage.getItem('token');
export function getToken(preState = token, action) {
  let { type, data } = action;
  switch (type) {
    case 'token':
      setLocalStorage('token', data);
      return data;
    default:
      return preState;
  }
}

//blog category tags
let categoryTags = {};
export function getCategoryTags(preState = categoryTags, action) {
  let { type, data } = action;
  switch (type) {
    case 'save_tag':
      return data;
    default:
      return preState;
  }
}

//blog category tags
let blogSearchKeyword = '';
export function getBlogSearchkeyword(preState = blogSearchKeyword, action) {
  let { type, data } = action;
  switch (type) {
    case 'save_keyword':
      return data;
    case 'clear_keyword':
      return blogSearchKeyword;
    default:
      return preState;
  }
}

// Fuzzy search
let searchData = [];
export function getFuzzySearchData(preState = searchData, action) {
  let { type, data } = action;
  switch (type) {
    case 'save_fuzzy_search':
      return data;
    default:
      return preState;
  }
}

// 用户订阅会员信息
let initInfo = {
  name: '',
  email: '',
  phone: '',
  phoneCode: 1,
  phoneCountry: 'US',
  paymentPlan: '',
};
export function getMemberCardInfo(preState = initInfo, action) {
  let { type } = action;
  switch (type) {
    case 'change_card_user': {
      let newState = JSON.parse(JSON.stringify(preState));
      newState = action.data;
      return newState;
    }
    case 'clear_card_user': {
      return initInfo;
    }
    default: {
      return preState;
    }
  }
}

let christmasInfo = {};
export function getChristmasInfo(preState = christmasInfo, action) {
  let { type, data } = action;
  switch (type) {
    case 'save_christmas_info':
      return data;
    default:
      return preState;
  }
}

const couponInfo = {
  activityState: false, // 活动状态
  couponsShow: false, // 优惠卷弹框
};

export function CouponReducer(state = couponInfo, action) {
  let { type, data } = action;
  switch (type) {
    case TOGGLE_COUPON: {
      return { ...state, ...data };
    }
    default: {
      return state;
    }
  }
}
