import dayjs from 'untils/dateTime/day';

export const roundToNearestTime = (time, unit, amount) => {
  const dateTime = dayjs(time);

  const mod = amount - (dateTime.get(unit) % amount);
  if (mod >= amount) {
    return dateTime.add(amount, unit).startOf(unit);
  }

  return dateTime.add(mod, unit).startOf(unit);
};


export const formatDateTime = (date, format) => {
  return dayjs(date).format(format);
};

// 转换对应时区的时间(date是utc时间)
export const formatDateTimeZone = (date, zone, format) => {
  return dayjs(date).utc('z').tz(zone).format(format);
};

// 将本地时间转换成 UTC 时间
// dayjs.tz()是将某个格式时间指定为是某个时区的格式时间

export const formatDateTimeUtc = (date, format, zone) => {
  if (!zone) zone = getTimeZone();
  return dayjs.tz(date, zone).utc().format(format);
};

// 转换对应时区的时间(date是本地时间)
export const formatLocalDateTimeZone = (date, zone, format) => {
  return dayjs(date).tz(zone).format(format);
};

// 获取当前时区
export const getTimeZone = () => {
  return dayjs.tz.guess();
};
