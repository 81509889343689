/*
 *App
 */
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { mainRoutes, tokenRoutes } from 'routes'; // token路由
import Loading from './components/loading/Loading';
const queryClient = new QueryClient();

console.log(process.env);
// 路由配置
const App = ({ getToken }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <BrowserRouter>
        <React.Suspense fallback={<Loading />}>
          <Switch>
            {mainRoutes.map((route) => {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  exact
                  render={(routeProps) => {
                    return <route.component {...routeProps} />;
                  }}
                />
              );
            })}

            {/* {console.log(getLocalStorage('token'))} */}
            {!getToken ? (
              <Redirect to="/signIn" />
            ) : (
              tokenRoutes.map((route) => {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    exact
                    render={(routeProps) => {
                      return <route.component {...routeProps} />;
                    }}
                  />
                );
              })
            )}
            <Redirect to="/404" />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </QueryClientProvider>
  );
};
// react-redux状态
const mapStateToProps = (state) => {
  return {
    getToken: state.getToken,
  };
};

export default connect(mapStateToProps)(App);
