// import Tap from '@tapfiliate/tapfiliate-js';
import HeaderMeta from 'components/public/headerMeta/HeaderMeta';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import App from './App';
import './assets/style/public_style.scss';
import store from './redux/store';

// Tap.init(process.env.REACT_APP_TAP_ACCOUNT_ID);

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <HeaderMeta type="default" />
        <App />
      </Provider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
