import React from 'react';

// 支付详情
const PayDetails = React.lazy(() => import('pages/results/PayDetails'));

// 支付成功
const PaySuccessful = React.lazy(() => import('pages/state/PaySuccessful'));
// 支付失败
const PayFailure = React.lazy(() => import('pages/state/PayFailure'));
// 修改密码
const ChangePassword = React.lazy(() => import('pages/password/ChangePassword'));
// 个人中心
const Profile = React.lazy(() => import('pages/profile/Profile'));
// 个人信息
const PersonalInformation = React.lazy(() => import('pages/profile/PersonalInformation'));
// 编辑个人信息
const EditInformation = React.lazy(() => import('pages/profile/EditInformation'));
// 订单列表
const Flights = React.lazy(() => import('pages/flights/Flights'));
// 订单详情
const FlightsDetails = React.lazy(() => import('pages/flights/FlightsDetails'));

// 宠物订单详情
const PetDetails = React.lazy(() => import('pages/flights/PetDetails'));
// 包机列表
const Inquire = React.lazy(() => import('pages/inquire/Inquire'));
// 包机详情
const InquireDetails = React.lazy(() => import('pages/inquire/InquireDetails'));
// 乘客列表
const Passengers = React.lazy(() => import('pages/passengers/Passengers'));
// 添加&修改乘客
const UpdatePassengers = React.lazy(() => import('pages/passengers/UpdatePassengers'));
// 订单选择乘客
const FlightsPassenger = React.lazy(() => import('pages/passengers/FlightsPassenger'));

// 优惠卷
const Coupons = React.lazy(() => import('pages/coupons/Coupons'));
//会员中心
const MembershipCards = React.lazy(() => import('pages/account/membershipCards/MembershipCards'));
// 会员卡详情页
const MembershipCardDetails = React.lazy(() =>
  import('pages/account/membershipCardDetails/MembershipCardDetails')
);

// 登录页
const SignIn = React.lazy(() => import('pages/login/SignIn'));
// 登录页
const SignUp = React.lazy(() => import('pages/login/SignUp'));
// google绑定页
const Bound = React.lazy(() => import('pages/login/Bound'));
// facebook绑定页
const BoundFacebook = React.lazy(() => import('pages/login/BoundFacebook'));
// 微信绑定页
const WxBound = React.lazy(() => import('pages/login/WxBound'));

// 首页
const Home = React.lazy(() => import('pages/home/Home'));

// 获取报价
const CharterQuote = React.lazy(() => import('pages/charterQuote/CharterQuote'));
// Jet type
const Jet = React.lazy(() => import('pages/jet/Jet'));
// Jet card
const JetCard = React.lazy(() => import('pages/jetCard/JetCard'));
//法律问题下架 固定航班
// const SearchFlights = React.lazy(() => import('pages/searchFlights/SearchFlights'));

// 定期航班搜索列表/单程
// const SearchDetails = React.lazy(() => import('pages/searchFlights/SearchDetails'));
//搜索机场
const SearchAirport = React.lazy(() => import('pages/charterQuote/SearchAirport'));
// blog列表页面
const Blog = React.lazy(() => import('pages/blog/Blog'));
// blog详情页面
const BlogDetails = React.lazy(() => import('pages/blog/BlogDetails'));
// empty legs页面
const EmptyLeg = React.lazy(() => import('pages/emptyLeg/EmptyLeg'));
// empty legs详情页面
const EmptyLegDetails = React.lazy(() => import('pages/emptyLeg/EmptyLegDetails'));
// 会员落地页
const MembershipCard = React.lazy(() => import('pages/member/Membership'));
// 添加会员信息
const AddCardInformation = React.lazy(() => import('pages/member/AddCardInformation'));
// 会员卡订阅支付
const MemberCardPay = React.lazy(() => import('pages/member/MemberCardPay'));
// 会员中转页
const MemberLoading = React.lazy(() => import('pages/member/MemberLoading'));
// 会员开通成功
const MemberSuccessful = React.lazy(() => import('pages/member/MemberSuccessful'));
// 会员开通失败
const MemberFail = React.lazy(() => import('pages/member/MemberFail'));
// 非移动端提示
const Prompt = React.lazy(() => import('pages/state/Prompt'));
// 支付加载
const PayLoading = React.lazy(() => import('pages/state/PayLoading'));
// Facebook加载
const FacebookLoading = React.lazy(() => import('pages/state/FacebookLoading'));
// 忘记密码
const ForgotPassword = React.lazy(() => import('pages/password/ForgotPassword'));
// 结果详情
const ResultsDetails = React.lazy(() => import('pages/results/ResultsDetails'));
//隐私政策
const PrivacyPolicy = React.lazy(() => import('pages/commen/PrivacyPolicy'));
//使用条款
const TermOfUse = React.lazy(() => import('pages/commen/TermOfUse'));
//关于我们
const AboutUs = React.lazy(() => import('pages/commen/AboutUs'));
//联系我们
const ContactUs = React.lazy(() => import('pages/commen/ContactUs'));
//高尔夫
const Golf = React.lazy(() => import('pages/commen/Golf'));
//与我们合作
const PartnerWithUs = React.lazy(() => import('pages/commen/PartnerWithUs'));
// Cookies声明
const CookiesPolicy = React.lazy(() => import('pages/commen/CookiesPolicy'));
// 会员协议
const MembershipPolicy = React.lazy(() => import('pages/commen/MembershipPolicy'));
// AffiliateAgreement
const AffiliateAgreement = React.lazy(() => import('pages/commen/AffiliateAgreement'));
// 404
const Page404 = React.lazy(() => import('pages/notFoundPage/Page404'));

// 飞机销售页面
const JetSale = React.lazy(() => import('pages/jetSale'));
const JetSaleDetail = React.lazy(() => import('pages/jetSale/JetSaleDetail'));

// 经销商注册页面
const AffiliatePartner = React.lazy(() => import('pages/affiliatePartner'));

// 圣诞节活动页面
// const Christmas = React.lazy(() => import('pages/events/christmas'));
// const Checkout = React.lazy(() => import('pages/events/christmas/Checkout'));
// const SendInfo = React.lazy(() => import('pages/events/christmas/SendInfo'));
// const CheckoutLoad = React.lazy(() => import('pages/events/christmas/CheckoutLoading'));

// // 宠物航班
// const PetFriendlyFlight = React.lazy(() => import('pages/PetFriendlyFlight'));
// // 宠物航班详情页
// const PetFlightDetail = React.lazy(() => import('pages/PetFriendlyFlight/PetFlightDetail'));
// // 宠物航班预定详情页
// const BookingDetails = React.lazy(() =>
//   import('pages/PetFriendlyFlight/PetFlightDetail/BookingDetails')
// );
// // 宠物航班Book Now
// const PetFlightBookNow = React.lazy(() => import('pages/PetFriendlyFlight/PetFlightBookNow'));
// // 宠物航班Success
// const PetFlightSuccess = React.lazy(() => import('pages/PetFriendlyFlight/PetFlightSuccess'));
// // 宠物航班Fail
// const PetFlightFail = React.lazy(() => import('pages/PetFriendlyFlight/PetFlightFail'));

// // 定期航班支付详情页
// const SearchPayDetails = React.lazy(() => import('pages/results/SearchPayDetails'));
// // 订单详情
// const ScheduledDetails = React.lazy(() => import('pages/flights/ScheduledDetails'));

// // 定期航班订单选择乘客
// const SearchFlightsPassenger = React.lazy(() => import('pages/passengers/SearchFlightsPassenger'));

// 不登录状态下
export const mainRoutes = [
  {
    // 首页
    path: '/',
    title: 'home',
    component: Home,
  },

  {
    // 经销商
    path: '/affiliatePartner',
    title: 'affiliatePartner',
    component: AffiliatePartner,
  },

  {
    // 飞机销售
    path: '/jetSale',
    title: 'jetSale',
    component: JetSale,
  },
  {
    // 飞机销售类别
    path: '/jetSale/:category',
    title: 'jetSale',
    component: JetSaleDetail,
  },

  {
    // 获取报价页
    path: '/charterQuote',
    title: 'charterQuote',
    component: CharterQuote,
  },
  {
    // Jet type
    path: '/private-jets',
    title: 'jet',
    component: Jet,
  },
  {
    // Jet Card
    path: '/jet-card-membership',
    title: 'jetCard',
    component: JetCard,
  },
  // {
  //   //定期航班
  //   path: '/searchFlights',
  //   title: 'searchFlights',
  //   component: SearchFlights,
  // },
  // {
  //   //定期航班详情页
  //   // path: '/searchDetails',
  //   path: '/searchDetails/:tripIndex/:passenger/:city/:date',
  //   title: 'searchDetails',
  //   component: SearchDetails,
  // },

  {
    //搜索机场
    path: '/searchAirport/:type/:path',
    title: 'searchAirport',
    component: SearchAirport,
  },

  {
    // blog页
    path: '/blog',
    title: 'blog',
    component: Blog,
  },
  {
    // blog详情页
    path: '/Blog/:id',
    title: 'blogDetails',
    component: BlogDetails,
  },

  {
    // 空腿
    path: '/emptyLeg',
    title: 'emptyLeg',
    component: EmptyLeg,
  },
  {
    // 空腿
    path: '/emptyLegDetails/:id',
    title: 'emptyLegDetails',
    component: EmptyLegDetails,
  },
  {
    // 会员卡页
    path: '/membership',
    title: 'membership',
    component: MembershipCard,
  },

  {
    //填写会员信息页
    path: '/addCardInformation',
    title: 'addCardInformation',
    component: AddCardInformation,
  },
  {
    //会员订阅支付
    path: '/memberCardPay',
    title: 'memberCardPay',
    component: MemberCardPay,
  },
  {
    //会员加载页
    path: '/memberLoading',
    title: 'memberLoading',
    component: MemberLoading,
  },
  {
    // 退订会员提交成功
    path: '/memberSuccessful',
    title: 'memberSuccessful',
    component: MemberSuccessful,
  },
  {
    // 退订会员提交失败
    path: '/memberFail',
    title: 'memberFail',
    component: MemberFail,
  },
  {
    //会员权益页
    path: '/membershipPolicy',
    title: 'membershipPolicy',
    component: MembershipPolicy,
  },
  {
    // 隐私政策
    path: '/privacyPolicy',
    title: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  {
    // 使用条款
    path: '/termOfUse',
    title: 'TermOfUse',
    component: TermOfUse,
  },
  {
    // 附属协议
    path: '/affiliateAgreement',
    title: 'AffiliateAgreement',
    component: AffiliateAgreement,
  },
  {
    // 关于我们
    path: '/aboutUs',
    title: 'AboutUs',
    component: AboutUs,
  },
  {
    // 联系我们
    path: '/contactUs',
    title: 'ContactUs',
    component: ContactUs,
  },
  {
    // 高尔夫
    path: '/epicGolfClubMembers',
    title: 'EpicGolfCLubMembers',
    component: Golf,
  },
  {
    // 与我们合作
    path: '/partnerWithUs',
    title: 'PartnerWithUs',
    component: PartnerWithUs,
  },
  {
    // cookies政策
    path: '/cookies',
    title: 'Cookies',
    component: CookiesPolicy,
  },
  {
    // 404
    path: '/404',
    title: '404',
    component: Page404,
  },
  {
    // 注册页

    path: '/signUp',
    title: 'Sign Up',
    component: SignUp,
  },
  {
    // 登录页
    path: '/signIn',
    title: 'Sign In',
    component: SignIn,
  },
  {
    // facebook绑定页
    path: '/boundFacebook/:openId',
    title: 'Bound Facebook',
    component: BoundFacebook,
  },
  {
    // google绑定页
    path: '/bound/:id',
    title: 'Bound Google',
    component: Bound,
  },
  {
    // 微信绑定页
    path: '/wxBound/:id',
    title: 'Bound Wechat',
    component: WxBound,
  },
  {
    // 忘记密码
    path: '/forgotPassword',
    title: 'Forgot Password',
    component: ForgotPassword,
  },
  {
    // 非移动端提示
    path: '/prompt',
    title: 'Prompt',
    component: Prompt,
  },
  {
    // 支付加载
    path: '/payLoading',
    title: 'PayLoading',
    component: PayLoading,
  },
  {
    // Facebook登录加载
    path: '/FacebookLoading',
    title: 'Facebook Loading',
    component: FacebookLoading,
  },
  {
    // 结果详情页
    path: '/resultsDetails/:routId',
    title: 'Results Details',
    component: ResultsDetails,
  },
  // {
  //   // 宠物航班
  //   path: '/petFriendlyFlight',
  //   title: 'PetFriendlyFlight',
  //   component: PetFriendlyFlight,
  // },
  // {
  //   //宠物航班详情页
  //   path: '/petFriendlyFlight/detail/:id/:region',
  //   title: 'PetFlightDetail',
  //   component: PetFlightDetail,
  // },
  // {
  //   //宠物航班预定详情页
  //   path: '/petFriendlyFlight/detail/bookingDetails',
  //   title: 'BookingDetails',
  //   component: BookingDetails,
  // },
  // {
  //   //宠物航班 Book Now
  //   path: '/petFriendlyFlight/bookNow',
  //   title: 'PetFlightBookNow',
  //   component: PetFlightBookNow,
  // },
  // {
  //   //宠物航班 Success
  //   path: '/petFriendlyFlight/success',
  //   title: 'PetFlightSuccess',
  //   component: PetFlightSuccess,
  // },
  // {
  //   //宠物航班 Fail
  //   path: '/petFriendlyFlight/fail',
  //   title: 'PetFlightFail',
  //   component: PetFlightFail,
  // },
];

export const tokenRoutes = [
  {
    // 支付页
    path: '/payDetails',
    title: 'PayDetails',
    component: PayDetails,
  },
  // {
  //   // 定期航班支付页
  //   path: '/searchPayDetails',
  //   title: 'SearchPayDetails',
  //   component: SearchPayDetails,
  // },
  // {
  //   // 订单选择乘客
  //   path: '/searchFlightsPassenger/:id',
  //   title: 'SearchFlightsPassenger',
  //   component: SearchFlightsPassenger,
  // },
  // {
  //   // 订单详情
  //   path: '/scheduledDetails/:id',
  //   title: 'ScheduledDetails',
  //   component: ScheduledDetails,
  // },
  {
    // 支付成功
    path: '/paySuccessful/:data',
    title: 'paySuccessful',
    component: PaySuccessful,
  },
  {
    // 支付失败
    path: '/payFail',
    title: 'payFail',
    component: PayFailure,
  },
  {
    // 修改密码
    path: '/changePassword',
    title: 'ChangePassword',
    component: ChangePassword,
  },
  {
    // 个人中心
    path: '/profile',
    title: 'Profile',
    component: Profile,
  },
  {
    // 个人信息
    path: '/personalInformation',
    title: 'PersonalInformation',
    component: PersonalInformation,
  },
  {
    // 编辑个人信息
    path: '/editInformation',
    title: 'EditInformation',
    component: EditInformation,
  },
  {
    // 订单列表
    path: '/flightsList',
    title: 'Flights',
    component: Flights,
  },
  {
    // 订单详情
    path: '/flightsDetails/:id',
    title: 'FlightsDetails',
    component: FlightsDetails,
  },

  {
    // 宠物订单详情
    path: '/petDetails',
    title: 'PetDetails',
    component: PetDetails,
  },
  {
    // 包机列表
    path: '/inquireList',
    title: 'Inquire',
    component: Inquire,
  },
  {
    // 包机详情
    path: '/inquireDetails/:id',
    title: 'InquireDetails',
    component: InquireDetails,
  },
  {
    // 乘客列表
    path: '/passengers',
    title: 'Passengers',
    component: Passengers,
  },
  {
    // 添加&修改乘客
    path: '/updatePassengers/:id',
    title: 'UpdatePassengers',
    component: UpdatePassengers,
  },
  {
    // 订单选择乘客
    path: '/flightsPassenger/:id',
    title: 'FlightsPassenger',
    component: FlightsPassenger,
  },

  {
    // 优惠卷
    path: '/coupons',
    title: 'coupons',
    component: Coupons,
  },
  {
    // 会员中心
    path: '/membershipCards',
    title: 'membershipCards',
    component: MembershipCards,
  },
  {
    // 会员卡详情页
    path: '/membershipCardDetails/:id',
    title: 'MembershipCardDetails',
    component: MembershipCardDetails,
  },
];
